.payrollSummaryContainer {
  border: 1px solid rgb(240, 240, 255);
  border-radius: 8px;
  width: calc(50% - 6px);
  padding: 12px;
  display: flex;
  gap: 12px;
}
.tdsSummary {
  color: var(--purple);
  background-color: var(--purple-hover);
}
.ptSummary {
  color: var(--Payroll, #da5db6);
  background-color: #FFEEFA;

}
.tdsSummary > svg > path {
  stroke: var(--purple);
}
.pt > svg > path {
  stroke: var(--Payroll, #da5db6)
}
.overH{
  overflow: hidden;
}
.esicSummary {
  color: var(--moonstone);
  background-color: var(--moonstone-hover);
}
.esicSummary > svg > path {
  stroke: var(--moonstone);
}
.insuranceSummary {
  color: var(--pink);
  background-color: var(--pink-hover);
}
.insuranceSummary > svg > path {
  stroke: var(--pink);
}
.epfSummary {
  color: var(--teal);
  background-color: var(--teal-hover);
}
.epfSummary > svg > path {
  stroke: var(--teal);
}
.gratuitySummary {
  color: var(--secondary);
  background-color: var(--secondary-hover);
}
.gratuitySummary > svg > path {
  stroke: var(--secondary);
}
.loanSummary {
  color: var(--blue);
  background-color: var(--blue-hover);
}
.loanSummary > svg > path {
  stroke: var(--blue);
}
