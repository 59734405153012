.HolidayCardFrameContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 94%;

  /* height: calc(100% - 11px); */
  width: calc(80% - 24px);
  padding: 11% 17px 3% 0px;
}

@media screen and (min-width:1200px) and (max-width:1808px) {
  .HolidayCardFrameContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 103%;
    width: calc(80% - 24px);
    padding: 11% 17px 3% 0px;
  }
}

@media screen and (max-width:1200px) {
  .HolidayCardFrameContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: calc(80% - 24px);
    padding: 11% 17px 3% 0px;
  }
}

.homeHolidayFramehomeHolidayFrameInList {
  width: calc(100% - 80px);
  height: 50%;
  background-color: aliceblue;
  border-radius: 8.45708px;
  display: flex;
  align-items: center;
  position: relative;
  contain: content;
}

.dashboardHolidayCardContentHolder {
  width: 100%;
  display: flex;
  height: calc(100% - 51px);
}

.holidayFrameImageBackground {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  position: absolute;
}

.HolidayMonthList {
  width: 82%;
  margin: 12px;
  height: calc(100% - 24px);
  contain: content;
  overflow: auto;
  flex-shrink: 0;
}

.HolidayMonthListCover {
  width: 22%;
  /* box-shadow: 0px 2px 8px 0px #D8F0F2; */
  margin: 12px 6px;
  /* height: calc(100% - 24px); */
  contain: content;
  overflow: hidden;
  flex-shrink: 0;
}

.HolidayMonthListUpperDiv {
  width: 100%;
  box-shadow: -5px 2px 8px 0px #D8F0F2;
  height: 5px;
  contain: content;
  overflow: hidden;
  flex-shrink: 0;
  margin-bottom: -12px;
  background: #FFF;

  /* box-shadow: 0px -2px 8px 0px #D8F0F2; */
  position: relative;
  z-index: 80;
}

.HolidayMonthListLowerDiv {
  width: 100%;
  height: 20px;
  contain: content;
  overflow: hidden;
  flex-shrink: 0;
  margin-top: -12px;
  background: #FFF;
  box-shadow: -5px -2px 8px 0px #D8F0F2;
  position: relative;
  z-index: 80;
}

.HolidayMonthList::-webkit-scrollbar {
  display: none;
}

.HolidayMonth {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary);
  border: 1px solid #ededed;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49px;
}

.HolidayMonthActive {
  background-color: var(--primary);
  color: white;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  height: 100%;
}

.carousel .slider-wrapper {
  height: 100%;
}

.carousel.carousel-slider {
  height: 100%;
}

.carousel-root {
  height: 100%;
  width: 100%;
}