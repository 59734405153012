.createAddPositionPageContainer {
  position: absolute;
}

textArea {
  resize: vertical;
  /* user can resize vertically, but width is fixed */
}

.createfacPageContainer {
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
}

.createReqPageContainer {
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  z-index: 8;
}

.reqCreatePagePostitonForm {
  align-items: left;
}

.createFacContainer {
  width: 80%;
}

.closeIconHolder {
  margin-right: 16px !important;
  margin-top: 8px !important;
}

.closeIconHolder>img {
  height: 17px;
}

.createBodyReq {
  display: flex;
  height: calc(100% - 49px);
}

.createBody {
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  /* overflow-y: auto; */
  /* margin-left: 28px; */
}

.formBody {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.bodyContainer {
  height: calc(100% - 49px);
}

.formbodyContainer {
  overflow: auto;
  height: calc(100% - 65px);
  padding: 0 12px;
  padding-bottom: 65px;
}

.inputContentHolder {
  height: 31px !important;
  width: 95% !important;
}

.datetimeContentHolder {
  height: 34px !important;
  width: 95.8% !important;
}

.input-block {
  width: calc(50% - 20px);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #555555;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: 0.3s;
}

.placeHolderContainerSolo {
  padding: 0 !important;
  height: 34px !important;
  width: 95.8% !important;
}

.deletebtnflex {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.deletebtn {
  background: rgb(247, 29, 29);
  box-shadow: rgba(247, 29, 29, 0.2) 0px 3.64643px 7.29287px,
    rgba(0, 0, 0, 0.15) 0px 3.64643px 9.11609px inset;
  margin: 12px;
  padding: 6px 12px;
  outline: 0;
  border: 0;
  font-size: 15px;
  border-radius: 4px;
  color: white;
  min-width: 100px;
  font-family: "Poppins";
}

.formcustom {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;
  padding-top: 6px;
}

.formcustomthree {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3px;
  padding-top: 24px;
}

.formcustomthreecar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3px;
  padding-top: 24px;
  width: 100%;
}

.formgrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;
  padding-top: 12px;
}

.twoColumn {
  grid-template-columns: repeat(2, 1fr) !important;
}

.threeColumn {
  grid-template-columns: repeat(3, 1fr) !important;
}

.formcustomS {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;
  padding-top: 14px;
}

.gridItemVisitor {

  grid-column: span 2;
}

.placeHolderContainer {
  padding-left: 12px;
  width: 89%;
  height: 56%;
  background: #ffffff;
  border: 0.75px solid #bfbfbf;
}

.placeHolderContainer:hover {
  border: solid 1px var(--primary);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.add-btn {
  font-size: 20px;
  background: #1296b0;
  color: white;
  border-radius: 20%;
  border: none;
  padding: 0px 5px;
  margin: 19px 5px 0px 5px;
  /* margin: 0px 5px; */

}

.remove-btn {
  font-size: 20px;
  background: red;
  color: white;
  border-radius: 20%;
  padding: 0px 8px;
  margin: 0px 5px;
  border: none;
}

.placeHolderContainer:focus {
  border: solid 1px var(--primary);
  outline: 0;
  box-shadow: var(--primary) 0px 2px 5px -1px, var(--primary) 0px 1px 3px -1px;
}

.smallCalendarContainer {
  /* background: white url(../../Assets/CalendarIcon.svg) right no-repeat !important; */
  position: absolute;
  right: 38px;
  bottom: 17px;
}

.clipContainerHolder {
  position: relative;
  border: 1px solid #bfbfbf;
  height: 35px;
}

.clipContainerHolder>label {
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #bfbfbf;
  padding-left: 12px;
}

.dropDownHolder {
  /* background: white url(../../Assets/DropDownBlueIcon.svg) right no-repeat !important; */
  position: absolute;
  right: 38px;
  bottom: 20px;
}

.bodyContainer>form>.createLine {
  position: absolute;
  bottom: 65px;
}

.createLine {
  height: 2px;
  width: 100%;
}

.createHeader>label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 46px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #1296b0;
  margin-left: 22px;
}

.createFooterAddPosition {
  position: inherit !important;
}

.createFooter {
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 24px);
  position: absolute;
  bottom: 0;
  height: 65px;
  align-items: center;
  padding-right: 24px;
  background-color: white;
}

.createFooterButton1 {
  margin-right: 17px;
  color: #f71d1d;
  width: 84px;
  min-width: 84px;
  background: #ffffff;
}

.createFooterButton2 {
  color: #ffffff;
  background: #1296b0;
  box-shadow: 0px 4px 8px rgb(18 150 176 / 20%),
    inset 0px 4px 10px rgb(0 0 0 / 15%);
}

.buttonClass {
  font-weight: 400;
  font-size: 15px;
  padding: 3px 12px;
  border-width: 0px;
  border-style: outset;
  border-color: transparent;
  border-image: initial;
  border-radius: 5px;
  text-align: center;
  height: 29px;
}

.reqAddPosition {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.input-label {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #78503a;
  transition: 0.3s;
  margin-bottom: 7px;
}

.input-block input::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

.input-block input:-ms-input-placeholder {
  color: #ccc;
  opacity: 1;
}

.input-block input::placeholder {
  color: #ccc;
  opacity: 1;
}

.input-block:focus-within {
  border-color: #8c7569;
}

.input-block:focus-within .input-label {
  color: rgba(140, 117, 105, 0.8);
}