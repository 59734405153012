@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
/* font-family: 'Lato', sans-serif;
font-family: 'Nunito', sans-serif; */

:root {
  --primary: #1296b0;
  --primary-hover: #e9f3f4;
  --secondary: #f7941d;
  --secondary-hover: #fff4e5;
  --green: #0db53c;
  --green-hover: #dff3e5;
  --pink: #da5db6;
  --pink-hover: #ffeefa;
  --blue: #77a2c7;
  --blue-hover: #eaf5ff;
  --orange: #fb7158;
  --orange-hover: #fff2f0;
  --teal: #5bc4bf;
  --teal-hover: #ebfdfc;
  --magenta: #de64a1;
  --magenta-hover: #ffeef2;
  --moonstone: #6ab1bf;
  --moonstone-hover: #e7f3f5;
  --purple: #8a88ed;
  --purple-hover: #f0f0ff;
  --bg-dark: #071e3d;
  --bg-light: #fdfdfd;
  --grey: #e6e6e6;
  --light-grey: #d4d5d9;
  --bg-modal-dark: #c9ddf8;
  --bg-modal-light: #f5f5f5;
  --black: #000;
  --white: #fff;
  --primary-text: #555555;
  --secondary-text: #bfbfbf;
  --notification-red: #f71d1d;
  --notification-green: #00ab00;
  --pastel-background: rgba(216, 240, 242, 0.4);
  --global-hover: #e6e6e6;
  --table-back: #fbfbfb;
  --table-head-back: #f4fbfc;
  --form-background: #faf9f8;
  color: #555555;
  font-size: 14px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04);
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbdce3;
  border-radius: 3px;
}

* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

.App,
.App-hide {
  font-family: "Poppins", sans-serif !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

input {
  font-family: "Poppins", sans-serif !important;
}

/*
.App {
    grid-template-columns: repeat(18, minmax(0, 1fr));
    grid-template-areas: 
                        "nav nav nav nav nav nav nav nav nav nav nav nav nav nav nav nav nav nav"
                        "aside body body body body body body body body body body body body body body body body body";
}
.menuHide {
    grid-template-columns: repeat(18, minmax(0, 1fr));
    grid-template-areas: 
                        "nav nav nav nav nav nav nav nav nav nav nav nav nav nav nav nav nav nav"
                        "body body body body body body body body body body body body body body body body body body";
}

.App-hide {
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-template-areas: 
                        "body";
} */
.nav {
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  height: 4.5rem;
  width: 100%;
}

/* .nav {
    z-index: 2;
    grid-area: nav;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
} */

.aside {
  z-index: 1;
  position: fixed;
  height: calc(100% - 4.5rem);
  width: 100%;
  left: 0;
  top: 4.5rem;
  transition: 0.5s;
}

.noti {
  z-index: 1;
  position: fixed;
  height: calc(100% - 4.5rem);
  width: 100%;
  right: 0;
  top: 4.5rem;
  transition: 0.5s;
}

.serviceFloat {
  z-index: 1;
  position: fixed;
  height: calc(100% - 4.5rem);
  width: 300px;
  right: 0;
  top: 4.5rem;
  transition: 0.5s;
}

.menuHide {
  width: 0;
}

.body {
  position: fixed;
  top: 4.5rem;
  left: 0;
  right: 0;
  z-index: 0;
  width: 100%;
  height: calc(100% - 4.5rem);
}

.App-hide>.body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.App-light {
  background-color: var(--bg-light);
}

.App-dark {
  background-color: var(--bg-dark);
}

/* @media only screen and (max-width: 900px) {
    
    .App {
        min-height: 100vh;
        grid-template-columns: 13rem repeat(4, auto);
        grid-template-areas: 
                        "nav nav nav nav nav"
                        "aside body body body body";
    }

    .App-hide {
        grid-template-columns: repeat(1, auto);
        grid-template-areas: 
                            "nav"
                            "body";
    }

    .people {
        display: none
    }

} */

.link {
  text-decoration: none;
}

.cursorBackground:hover {
  background-color: var(--global-hover);
  cursor: pointer;
}

.cursor:hover {
  cursor: pointer;
}

.cursor label:hover {
  cursor: pointer;
}

.badge {
  background-color: red;
  color: white;
  border-radius: 10px;
  top: 5%;
  right: 7%;
  font-size: 12px;
  position: absolute;
}

.badge>label {
  margin: 0 6px;
  font-weight: bold;
}

h1 {
  font-size: 35px;
  font-weight: 500;
  margin: 0;
}

h2 {
  font-size: 30px;
  font-weight: 500;
  margin: 0;
}

h3 {
  font-size: 25px;
  font-weight: 500;
  margin: 0;
}

h4 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

h5 {
  font-size: 17px;
  font-weight: 500;
  margin: 0;
}

h6 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.horizontalLine {
  background-color: var(--grey);
  height: 3px;
  border-radius: 50px;
  flex-shrink: 0;
}

.horizontalLinePrimary {
  background-color: var(--primary);
  height: 3px;

  min-height: 3px;
  border-radius: 50px;
}

.horizontalLinePrimaryss {
  background-color: #1296b0a6;
  height: 1px;
  width: 30px;
  min-height: 2px;
  border-radius: 50px;

}

.verticalLine {
  height: 100%;
  width: 2px;
  background-color: var(--grey);
  border-radius: 50px;
}

p {
  margin: 0;
}

button:hover {
  cursor: pointer;
}

.not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* @media only screen and (max-width: 600px) {

    .App {
        min-height: 100vh;
        grid-template-rows: 5rem auto 3.5rem;
        grid-template-columns: repeat(1, auto);
        grid-template-areas: 
                        "nav"
                        "body"
                        "aside";
    }

    .App-hide {
        grid-template-columns: repeat(1, auto);
        grid-template-areas: 
                            "nav"
                            "body";
    }

    .aside {
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .people {
        display: none
    }

} */

.alertContainer {
  height: 100%;
  background-color: white;
  box-shadow: 0px 3.56872px 17.8436px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 16px 16px 0 16px;
  contain: content;
  width: 100%;
}

.react-confirm-alert-overlay {
  background: #00000050 !important;
  z-index: 1 !important;
}

.react-confirm-alert {
  max-height: 95%;
  height: fit-content;
  max-width: 700px;
  display: flex;
  min-width: 35%;
}

.popupFormContainer {
  height: calc(100% - 50px);
  overflow: auto;
  background-color: var(--form-background);
  border-radius: 8px;
  margin-top: 12px;
}

.homeEngageContainer {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
  margin-top: 24px;
  display: flex;
  height: calc(100% - 84px);
}

.homeEngageAside {
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.homeEngageAsideMenuContainer {
  width: calc(100% - 18px);
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 6%);
  border-radius: 8px;
  margin: 12px 6px 12px 12px;
  contain: content;
  max-height: calc(100% - 24px);
  overflow-y: auto;
}

.menuOptions {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  contain: content;
  padding: 16px 6px 16px 0;
}

.menuOptions>label:hover {
  cursor: pointer !important;
}

.menuOptions:hover {
  cursor: pointer;
  background-color: var(--global-hover);
}

.menuOptions>.verticalLine {
  left: 0;
  height: 100%;
  width: 5px;
  background-color: transparent;
  position: absolute;
  border-radius: 0;
}

.menuOptions>img {
  margin: 0 20px;
}

.menuOptionsActive>.verticalLine {
  background-color: var(--primary);
}

.menuOptionsActive {
  background-color: var(--primary-hover);
  color: var(--primary);
}

.homeEngageMainAsideContainer {
  height: 70%;
  display: flex;
  margin-bottom: 7rem;
  min-width: 360px;
  max-width: 360px;
}

.homeEngageMainContainer {
  width: calc(100% - 250px);
  display: flex;
  overflow: auto;
}

.customConfirmAlertContainer {
  font-size: 13px;
  color: #bebebe;
  margin-left: 30px;
}

.customConfirmAlertContainer h1 {
  text-align: left !important;
  font-size: 16px;
  font-weight: 700;
  color: #424242;
  margin-bottom: 15px;
}

.customConfirmAlertContainer .react-confirm-alert-button-group {
  justify-content: flex-end;
  margin-top: 20px;
}

.customConfirmAlertContainer .react-confirm-alert-button-group button:nth-child(1) {
  background: none;
  color: red;
  font-size: 1rem;
  font-weight: 600;
  margin-right: 10px;
  padding: 8px 35px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.customConfirmAlertContainer .react-confirm-alert-button-group button:nth-child(2) {
  font-size: 1rem;
  font-weight: 600;
  padding: 8px 35px;
  color: #fff;
  background-color: #1296b0;
}

.customConfirmAlertContainer .react-confirm-alert-button-group button:nth-child(3) {
  font-size: 1rem;
  font-weight: 600;
  padding: 8px 35px;
  color: #fff;
  background-color: #1296b0;
}

.css-apqrd9-MuiTableBody-root>td {
  /* display: table-row-group; */
  vertical-align: top;
  margin: 19px 5px 0px 5px;
}

.css-apqrd9-MuiTableBody-root>td:first-child {
  padding-top: 19px;
}