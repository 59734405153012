.carousel .control-next.control-arrow:before {
  border-left: 8px solid grey;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid grey;
}
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
}

.galleryImageContainer1 {
  position: relative;
}
.galleryImagesNameContainer {
  width: 100%;
  position: absolute;
  bottom: 8.4%;
}
.galleryImagesNameWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.galleryImagesNameWrapper .galleryImageName {
  color: #fff;
  width: 90%;
  background-image: linear-gradient(0deg, #02020282, rgba(0, 0, 0, 0));

  font-weight: 600;
  padding: 5px 20px;
}
.carousel.carousel-slider .control-arrow {
  color: #fff;
  font-size: 26px;
  bottom: 0;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin-top: 0;
  padding: 5px;
  right: 0;
  top: 40% !important;
}
