.homeEngageMainCenterContainer {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  position: relative;
}
.homeEngageMainCenterContainer::-webkit-scrollbar {
  display: none;
}
.activeComment {
  color: #1296b0 !important;
}
.postUpdateContainerParent {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  padding: 0 0 0 1%;
}
.postUpdateContainer {
  padding: 0 5px;
  border-radius: 8px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  background: var(--white, #fff);
}

.postUpdateContainer > img {
  border-radius: 100%;
  margin: 12px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.postUpdateContainer > input {
  flex-grow: 1;
  margin-right: 12px;
  border: transparent;
  outline: none;
  cursor: pointer;
  padding: 27px 0;
}
.postShareName {
  font-weight: 500;
  font-size: 12px !important;
}
.homeEngagePostContainer {
  width: 100%;
  border-radius: 8px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  contain: content;
  padding: 0 3% 0 4%;
}

.commentBodyContainer .postBodyImgContainer img {
  width: 100%;
}
.homeEngagePostContainer
  .engagePostContentHolder
  .postBodyContainer
  .postBodyTextContainer {
  color: var(--people-sol-dark-grey, #555);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}
.newPostBodyContainer textarea {
  resize: none;
}
.homeEngagePostContainer
  .engagePostContentHolder
  .postBodyContainer
  .postBodyImgContainer {
  overflow: hidden;
  border-radius: 0 0 10px 10px;
}
.homeEngagePostContainer
  .engagePostContentHolder
  .postBodyContainer
  .postBodyImgContainer
  img {
  max-width: 100%;
  width: auto;
  max-height: 500px;
  height: auto;
  overflow: hidden;
  overflow: hidden;
  object-fit: cover;
}
.postSharedContainer {
  background-color: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7px;
  border-radius: 15px 15px 0px 0px;
}
.postSharedContainer .leftPostHeaderContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.postSharedContainer .rightSocialPostHeaderContainer {
  padding: 0px 10px;
}
.postSharedContainer .leftPostHeaderContainer > img {
  border-radius: 100%;
  margin: 5px 5px 10px 8px;
  width: 35px;
  height: 35px;
}
.postShareTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.postShareTextContainer > label {
  font-weight: 500;
  font-size: 10px;
}
.postHrTime {
  font-size: 10px;
  font-weight: 400;
  color: var(--secondary-text);
}
.engagePostContentHolder {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.engagePostContentHolder > .horizontalLine {
  height: 1px;
}
.postHeaderContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 17px;

  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
}
.postHeaderContainer .leftPostHeaderContainer {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}
.postHeaderContainer .rightSocialPostHeaderContainer {
  padding: 0 10px;
}

.postMainTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}
.postMainTextContainer > label {
  font-size: 13px;
  cursor: pointer;
  line-height: 19px;
}
.socialDateAndTime {
  color: #bfbfbf;
  font-size: 12px !important;
}
label.postMainName {
  font-weight: 500;
  font-size: 15px !important;
  color: var(--primary);
}
.socialNewPostPopupContainer {
  width: 100%;
  height: 100%;
}
.socialNewPostPopupContainer .socialNewPostPopupWrapper {
  padding: 20px 30px;
}
.socialNewPostPopupContainer .socialNewPostHeaderContainer {
  height: 70px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
}
.engagePostContentHolder .totalPostActivity {
  padding: 5px 20px 10px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.engagePostContentHolder .totalPostActivity p {
  font-size: 11px;
  color: #bfbfbf;
  cursor: pointer;
}
.engagePostContentHolder .totalPostActivity .likes {
  margin-right: 5px;
}
.engagePostContentHolder .totalPostActivity .dislikes {
  margin-left: 12px;
}
.engagePostContentHolder .totalPostActivity .commentAndShare span:nth-child(2) {
  padding-left: 20px;
}
.engagePostContentHolder .totalPostActivity span {
  font-size: 11px;
  color: #bfbfbf;
  cursor: pointer;
}
.socialAllPostContainer {
  border-radius: 15px;
  margin-bottom: 30px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
}

.postBottomContainer {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.postBottomContainer .postBottomLeftContainer {
  display: flex;
  align-items: center;
}
.postBottomContainer .postBottomLeftContainer .postLikeHolder,
.postBottomContainer .postBottomLeftContainer .postDislikeHolder,
.postBottomContainer .postBottomLeftContainer .postCommentHolder,
.postBottomContainer .postBottomLeftContainer .postShareHolder,
.postBottomContainer .postBottomRightContainer .postSaveHolder {
  display: flex;
  align-items: center;
  margin-right: 40px;
  color: #bfbfbf;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.11px;
  gap: 6px;
  cursor: pointer;
  padding: 20px 0;
}
.postBottomContainer .postBottomLeftContainer .postLikeHolder span,
.postBottomContainer .postBottomLeftContainer .postDislikeHolder span,
.postBottomContainer .postBottomLeftContainer .postCommentHolder span,
.postBottomContainer .postBottomLeftContainer .postShareHolder span,
.postBottomContainer .postBottomRightContainer .postSaveHolder span {
  cursor: pointer;
}

.postBottomContainer .postBottomRightContainer .postSaveHolder {
  margin: 0 !important;
}
.postLikeHolder {
  color: var(--primary) !important;
}
.postLikeHolder > svg > path {
  stroke: var(--primary-text);
}
.postDislikeHolder {
  color: var(--primary) !important;
}
.postDislikeHolder > svg > path {
  stroke: var(--primary-text);
}
.likeDislikeContainer {
  display: flex;
  align-items: center;
}
.totalLikePopupContainer .totalLikePopupList {
  display: flex;
  align-items: center;
}
.totalLikePopupContainer .totalLikePopupList .totalLikePopupImg {
  width: 55px;
  height: 55px;
  margin-right: 15px;
  position: relative;
}
.totalLikePopupContainer .totalLikePopupList .totalLikePopupImg .likedIcon {
  width: 22px !important;
  height: 22px !important;
  top: 40px;
  left: 35px;
  position: absolute;
}
.totalLikePopupContainer
  .totalLikePopupList
  .totalLikePopupImg
  .likedIcon
  > img {
  height: 23px;
}
.totalLikePopupContainer .totalLikePopupList .totalLikePopupImg > img {
  height: 55px;
  width: 55px;
  height: 100%;
  border-radius: 100%;
}
.totalLikePopupContainer .totalLikePopupList .totalLikePopupText {
  width: 100%;
  border-bottom: 1px solid rgb(226, 226, 226);
  padding: 15px 0;
}
.totalLikePopupContainer .totalLikePopupList .totalLikePopupText h5 {
  color: #555;
  font-family: Poppins;
  font-size: 16.869px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.99px;
}
.totalLikePopupContainer .totalLikePopupList span {
  color: #747474;
  font-family: Poppins;
  font-size: 12.869px;
  font-style: normal;
}
.totalLikePopupContainer .totalLikePopupList p {
  color: #747474;
  font-family: Poppins;
  font-size: 12.869px;
  font-style: normal;
}
.totalLikePopupContainer .noLike {
  display: flex;
  align-items: center;
  justify-content: center;
}
.totalLikePopupContainer .noLike h3 {
  color: #bfbfbf;
  font-size: 13px;
}
.likePopupBox1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.likePopupBox2 {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  border-radius: 6px;
  max-height: 60%;
  min-width: 40%;
  min-height: 70%;
  max-width: 60%;
}
.eventActionPopupBox1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.eventActionPopupBox2 {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  max-height: 60%;
  border-radius: 6px;
  min-width: 670px;
  min-height: 580px;
  max-width: 700px;
}

.likePopupContent {
  justify-content: flex-start;
}

.commentPopupBox1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.commentPopupBox2 {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  left: -40px;
  min-width: 700px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  max-height: 85%;
  border-radius: 6px;
  min-height: 280px;
  max-width: 700px;
  margin-top: 40px;
}
.deletePostPopupBox1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.deletePostPopupBox2 {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  left: -40px;
  background: #fff;
  border-radius: 8px;
  border: none;
  max-height: 85%;
  min-height: 250px;
  max-width: 700px;
  margin-top: 40px;
}
.deletePostPopupBox1 .popupContentHolder {
  flex-grow: 0;
  justify-content: unset;
}
.commentBodyContainer {
  padding: 5px 0px 20px 0px;
}
.commentBodyContainer .postBodyImgContainer {
  width: 100%;
}
.commentBodyContainer .postBodyTextContainer {
  color: var(--people-sol-dark-grey, #555);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.849px;
  padding-bottom: 10px;
  display: flex;
}
.postBodyTextContainer {
  margin: 15px 20px;
}
.postBodyTextContainer p {
  word-break: break-all;
}
.postBodyTextContainer a {
  color: #1296b0;
  text-decoration: none;
}
.postBodyTextContainer a:hover {
  text-decoration: underline;
}

.mainCommentContainer .commentContainer {
  background-color: #f0fafb;
  border-radius: 10px;
  display: flex;
  padding: 15px 20px;
  position: relative;
}
.mainCommentContainer .commentContainer .commentTextContainer {
  width: 95%;
}
.mainCommentContainer .commentContainer .commentTextContainer p {
  color: var(--people-sol-dark-grey, #555);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding-top: 7px;
}
.commentTextContainer a {
  color: #1296b0;
}
.mainCommentContainer
  .commentContainer
  .commentTextContainer
  .commenteeDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8f0f2;
  padding-bottom: 7px;
}
.mainCommentContainer
  .commentContainer
  .commentTextContainer
  .commenteeDetails
  .commenteeDetailsText
  h5 {
  color: var(--people-s-ol-primery, #1296b0);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.522px;
}
.mainCommentContainer
  .commentContainer
  .commentTextContainer
  .commenteeDetails
  .commenteeDetailsText
  h6
  span {
  color: #555;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.522px;
}
.repliedDetails h6 span {
  color: #555;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.522px;
}
.mainCommentContainer
  .commentContainer
  .commentTextContainer
  .commenteeDetails
  .commenteeDetailsText
  h6 {
  color: #555;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.522px;
}
.mainCommentContainer .commentContainer .commentImgContainer {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}
.mainCommentContainer .commentContainer .commentImgContainer img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  cursor: pointer;
}
.mainCommentContainer .noComment {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainCommentContainer .noComment h3 {
  color: #bfbfbf;
  font-size: 12px;
  margin: 20px 0;
  font-weight: 400;
}
.commentContainerList .likeAndReply {
  margin: 2px 0px;
  height: fit-content;
  padding-left: 30px;
  position: relative;
}
.commentContainerList .mainRepliedContainer {
  width: 100%;
  margin: 10px 0px;
  padding-left: 50px;
}
.commentContainerList .repliedContainer {
  display: flex;
  background-color: #f0fafb;
  border-radius: 10px;
  padding: 15px;
  margin: 10px 0 0 0;
  position: relative;
}
.commentContainerList .repliedContainer .repliedTextContainer {
  width: 80%;
}
.commentContainerList .repliedContainer .repliedTextContainer p {
  color: var(--people-sol-dark-grey, #555);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 7px 0px;
}
.repliedTextContainer a {
  color: #1296b0;
}
.commentContainerList .repliedContainer .repliedTextContainer .repliedDetails {
  border-bottom: 1px solid #d8f0f2;
  padding-bottom: 7px;
}
.commentContainerList
  .repliedContainer
  .repliedTextContainer
  .repliedDetails
  h5 {
  color: var(--people-s-ol-primery, #1296b0);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.522px;
}
.commentContainerList
  .repliedContainer
  .repliedTextContainer
  .repliedDetails
  h5
  span {
  color: #555;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.522px;
}
.commentContainerList
  .repliedContainer
  .repliedTextContainer
  .repliedDetails
  h6 {
  color: #555;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.522px;
}
.commentContainerList .repliedContainer .repliedImgContainer {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}
.commentContainerList .repliedContainer .repliedImgContainer img {
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 100%;
}
.repliedTextContainer a {
  text-decoration: none;
}
.newPostPopup1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.newPostPopup2 {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  border-radius: 6px;
  min-height: 70%;
  min-width: 45%;
  max-width: 45%;
  max-height: 80%;
  margin-top: 50px;
}
.newPostPopup2 .popupContentHolder {
  justify-content: unset;
  flex-grow: 0;
}
.newPostBodyContainer {
  padding: 0 0 12% 0;
}
.createPostInputText {
  color: var(--black);
  background-color: var(--white);
  border-radius: 4px;
  margin: 12px;
  padding: 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
  outline: 0;
  height: auto;
  width: calc(100% - 24px);
  font-family: "Poppins";
  font-size: 14px;
  outline: none;
  border: none;
}
.createPostInputText .createPostInputText__suggestions {
  position: absolute;
  left: 51px;
  top: 1px;
  z-index: 1;
  margin-top: 14px;
  min-width: 100px;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
  padding: 20px;
  height: 350px;
  overflow: auto;
}
.createPostInputText .createPostInputText__input {
  outline: none;
  border: none;
}

.createPostInputText::-webkit-scrollbar {
  width: 0.1em;
}
.createPostInputText::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgb(255, 255, 255);
}
.createPostInputText::-webkit-scrollbar-thumb {
  background-color: white;
}

.createPostImg2 {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-gap: 3px;
}
.createPostImg3 {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: auto;
  grid-gap: 3px;
}
.createPostImg4 {
  position: relative;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: auto;
  grid-gap: 3px;
}
.createPostImg5 {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: auto;
  grid-gap: 3px;
}

.createPostImg2 .createSubImage2a {
  width: 100%;
  height: 335px;
  display: grid;
  grid-gap: 3px;
}
.createPostImg2 .createSubImage2a img:nth-child(1),
.createPostImg2 .createSubImage2a video {
  width: 100% !important;
  height: 335px !important;
  display: grid;
  grid-gap: 3px;
  object-fit: cover;
}
.createPostImg2 .createSubImage2b {
  display: grid;
  grid-gap: 3px;
}
.createPostImg2 .createSubImage2b img:nth-child(1),
.createPostImg2 .createSubImage2b video {
  width: 100% !important;
  height: 335px !important;
  object-fit: cover;
}

.createPostImg3 .createSubImage3a {
  width: 100%;
  height: 335px;
  display: grid;
  grid-gap: 3px;
  display: flex;
  justify-content: center;
}
.createPostImg3 .createSubImage3a img:nth-child(1),
.createPostImg3 .subImage3a video {
  width: 100% !important;
  height: 335px !important;
  display: grid;
  grid-gap: 3px;
  object-fit: cover;
}
.createPostImg3 .createSubImage3b {
  display: grid;
  grid-gap: 3px;
}
.createPostImg3 .createSubImage3b img:nth-child(1),
.createPostImg3 .createSubImage3b img:nth-child(3),
.createPostImg3 .createSubImage3b video {
  width: 100% !important;
  height: 165px !important;
  object-fit: cover;
}

.createPostImg4 .createSubImage4a {
  width: 100%;
  height: 335px;
  display: grid;
  grid-gap: 3px;
}
.createPostImg4 .createSubImage4a img:nth-child(1),
.createPostImg4 .createSubImage4a video {
  width: 100% !important;
  height: 335px !important;
  object-fit: cover;
  display: grid;
  grid-gap: 3px;
}
.createPostImg4 .createSubImage4b {
  display: grid;
  grid-gap: 3px;
}
.createPostImg4 .createSubImage4b img:nth-child(1),
.createPostImg4 .createSubImage4b img:nth-child(3),
.createPostImg4 .createSubImage4b img:nth-child(5),
.createPostImg4 .createSubImage4b video {
  width: 100% !important;
  height: 110px !important;
  object-fit: cover;
}

.newPostBodyImgContainer5 .createSubImage5a {
  width: 100%;
  height: 335px;
  display: grid;
  grid-gap: 3px;
}
.createPostImg5 .createSubImage5a img:nth-child(1),
.createPostImg5 .createSubImage5a video {
  width: 100% !important;
  height: 335px !important;
  display: grid;
  grid-gap: 3px;
  object-fit: cover;
}
.createPostImg5 .createSubImage5b {
  display: grid;
  grid-gap: 3px;
}
.createPostImg5 .createSubImage5b img:nth-child(1),
.createPostImg5 .createSubImage5b img:nth-child(3),
.createPostImg5 .createSubImage5b img:nth-child(5),
.createPostImg5 .createSubImage5b video {
  width: 100% !important;
  height: 110px !important;
  object-fit: cover;
}

.shareImgContainer2 {
  grid-template-columns: 50% 50%;
}
.shareImgContainer3 {
  grid-template-columns: 60% 40%;
}
.shareImgContainer4 {
  grid-template-columns: 60% 40%;
}

.newPostBodyImgMainWrapper .newPostBodyImgContainer {
  display: flex;
  justify-content: center;
}
.newPostBodyContainer .newPostBodyImgContainer {
  height: 360px;
  width: 610px;
  display: flex;
  justify-content: center;
}
.newPostBodyContainer .newPostBodyImgContainer img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.newPostBodyImgMainContainer {
  height: 300px;
  width: 450px;
  margin-left: 20px;
}
.newPostBodyImgMainWrapper {
  position: relative;
  overflow: hidden;
}
.newPostBodyImgContainer2 {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-gap: 3px;
}

.newPostBodyImgContainer3 {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: auto;
  grid-gap: 3px;
}
.newPostBodyImgContainer4 {
  position: relative;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: auto;
  grid-gap: 3px;
}

.newPostBodyImgContainer5 {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: auto;
  grid-gap: 3px;
}
.newPostBodyImgContainer2 .subImage2a {
  width: 100%;
  height: 335px;
  display: grid;
  grid-gap: 3px;
  object-fit: cover;
}
.newPostBodyImgContainer2 .subImage2a img,
.newPostBodyImgContainer2 .subImage2a video {
  width: 100%;
  height: 335px;
  display: grid;
  grid-gap: 3px;
  object-fit: cover;
}
.newPostBodyImgContainer2 .subImage2b {
  display: grid;
  grid-gap: 3px;
}
.newPostBodyImgContainer2 .subImage2b img,
.newPostBodyImgContainer2 .subImage2b video {
  width: 100%;
  height: 335px;
  object-fit: cover;
}

.newPostBodyImgContainer3 .subImage3a {
  width: 100%;
  height: 335px;
  display: grid;
  grid-gap: 3px;
  display: flex;
  justify-content: center;
}
.newPostBodyImgContainer3
  .subImage3a
  img
  .newPostBodyImgContainer3
  .subImage3a
  video {
  width: 100% !important;
  height: 335px !important;
  display: grid;
  grid-gap: 3px;
  object-fit: cover;
}
.newPostBodyImgContainer3 .subImage3b {
  display: grid;
  grid-gap: 3px;
}
.newPostBodyImgContainer3 .subImage3b img,
.newPostBodyImgContainer3 .subImage3b video {
  width: 100% !important;
  height: 166px !important;
  object-fit: cover;
}

.newPostBodyImgContainer4 .subImage4a {
  width: 100%;
  height: 335px;
  display: grid;
  grid-gap: 3px;
}
.newPostBodyImgContainer4 .subImage4a img,
.newPostBodyImgContainer4 .subImage4a video {
  width: 100% !important;
  height: 335px !important;
  display: grid;
  grid-gap: 3px;
  object-fit: cover;
}
.newPostBodyImgContainer4 .subImage4b {
  display: grid;
  grid-gap: 3px;
}
.newPostBodyImgContainer4 .subImage4b img,
.newPostBodyImgContainer4 .subImage4b video {
  width: 100% !important;
  height: 110px !important;
  object-fit: cover;
}

.newPostBodyImgContainer5 .subImage5a {
  width: 100%;
  height: 335px;
  display: grid;
  grid-gap: 3px;
}
.newPostBodyImgContainer5 .subImage5a img,
.newPostBodyImgContainer5 .subImage5a video {
  width: 100% !important;
  height: 335px !important;
  display: grid;
  grid-gap: 3px;
  object-fit: cover;
}
.newPostBodyImgContainer5 .subImage5b {
  display: grid;
  grid-gap: 3px;
}
.newPostBodyImgContainer5 .subImage5b img,
.newPostBodyImgContainer5 .subImage5b video {
  width: 100% !important;
  height: 110px !important;
  object-fit: cover;
}

.editPostBodyImgContainer2 {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-gap: 3px;
}

.editPostBodyImgContainer3 {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: auto;
  grid-gap: 3px;
}
.editPostBodyImgContainer4 {
  position: relative;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: auto;
  grid-gap: 3px;
}

.editPostBodyImgContainer5 {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: auto;
  grid-gap: 3px;
}
.editPostBodyImgContainer2 .subImage2a {
  width: 100%;
  height: 335px;
  display: grid;
  grid-gap: 3px;
  object-fit: cover;
}
.editPostBodyImgContainer2 .subImage2a .editimg,
.editPostBodyImgContainer2 .subImage2a .editimg {
  width: 100%;
  height: 335px;
  display: grid;
  grid-gap: 3px;
  object-fit: cover;
}
.editPostBodyImgContainer2 .subImage2b {
  display: grid;
  grid-gap: 3px;
}
.editPostBodyImgContainer2 .subImage2b .editimg,
.editPostBodyImgContainer2 .subImage2b .editimg {
  width: 100%;
  height: 335px;
  object-fit: cover;
}

.editPostBodyImgContainer3 .subImage3a {
  width: 100%;
  height: 335px;
  display: grid;
  grid-gap: 3px;
  display: flex;
  justify-content: center;
}
.editPostBodyImgContainer3
  .subImage3a
  .editimg
  .editPostBodyImgContainer3
  .subImage3a
  .editimg {
  width: 100% !important;
  height: 335px !important;
  display: grid;
  grid-gap: 3px;
  object-fit: cover;
}
.editPostBodyImgContainer3 .subImage3b {
  display: grid;
  grid-gap: 3px;
}
.editPostBodyImgContainer3 .subImage3b .editimg,
.editPostBodyImgContainer3 .subImage3b .editimg {
  width: 100% !important;
  height: 166px !important;
  object-fit: cover;
}

.editPostBodyImgContainer4 .subImage4a {
  width: 100%;
  height: 335px;
  display: grid;
  grid-gap: 3px;
}
.editPostBodyImgContainer4 .subImage4a .editimg,
.editPostBodyImgContainer4 .subImage4a .editimg {
  width: 100% !important;
  height: 335px !important;
  display: grid;
  grid-gap: 3px;
  object-fit: cover;
}
.editPostBodyImgContainer4 .subImage4b {
  display: grid;
  grid-gap: 3px;
}
.editPostBodyImgContainer4 .subImage4b .editimg,
.editPostBodyImgContainer4 .subImage4b .editimg {
  width: 100% !important;
  height: 110px !important;
  object-fit: cover;
}

.editPostBodyImgContainer5 .subImage5a {
  width: 100%;
  height: 335px;
  display: grid;
  grid-gap: 3px;
}
.editPostBodyImgContainer5 .subImage5a .editimg,
.editPostBodyImgContainer5 .subImage5a .editimg {
  width: 100% !important;
  height: 335px !important;
  display: grid;
  grid-gap: 3px;
  object-fit: cover;
}
.editPostBodyImgContainer5 .subImage5b {
  display: grid;
  grid-gap: 3px;
}
.editPostBodyImgContainer5 .subImage5b .editimg,
.editPostBodyImgContainer5 .subImage5b .editimg {
  width: 100% !important;
  height: 110px !important;
  object-fit: cover;
}

.normalImgContainer {
  height: auto;
  max-height: 450px;
  width: auto;
  max-width: 640px;
  object-fit: cover;
}
.imgHideMainBox {
  height: 110px;
  width: 39.5%;
  position: absolute;
  background-color: #00000080;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgHideRoundBox {
  width: 65px;
  height: 65px;
  background-color: #bfbfbf61;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.imgHideRoundBox span {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}
.imageDeselectIcon {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  top: 6px;
  right: 6px;
  cursor: pointer;
  z-index: 9;
}
.newPostHeaderContainer {
  background-color: #ffffff;
  width: 100%;
  display: flex;
}
.newPostHeaderContainer > img {
  border-radius: 100%;
  margin: 0px 12px;
  width: 50px;
  height: 50px;
}
.newPostHeaderTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}
.newPostHeaderTextContainer h5 {
  color: var(--people-s-ol-primery, #1296b0);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.849px;
  padding-bottom: 5px;
}
.newPostHeaderTextContainer p {
  color: var(--people-sol-dark-grey, #555);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14.717px;
}
.newPostHeaderTextContainer span {
  color: var(--people-sol-dark-grey, #919191);
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  line-height: 14.717px;
}
.newPostBodyContainer .newPostBottomContainer {
  position: absolute;
  width: 100%;
  bottom: 0px;
  border-top: 1px solid rgb(238, 238, 238);
  background-color: #fff;
  z-index: 999;
}
.newPostBodyContainer .newPostBottomContainer .newPostBottomWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newPostBodyContainer .newPostBottomContainer .newPostBottomWrapper img {
  cursor: pointer;
}
.newPostBodyContainer .newPostBottomContainer .newPostBottomWrapper p {
  color: var(--people-sol-dark-grey, #555);
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 13.585px;
  text-wrap: nowrap;
}
.newPostBodyContainer .activityIconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newPostBodyContainer .activityIconContainer .imgIconContainer,
.newPostBodyContainer .activityIconContainer .greetingsIconContainer,
.newPostBodyContainer .activityIconContainer .videoIconContainer,
.newPostBodyContainer .activityIconContainer .documentIconContainer,
.newPostBodyContainer .activityIconContainer .gifIconContainer,
.newPostBodyContainer .activityIconContainer .linkIconContainer {
  padding: 4% 5%;
  margin: 5px 0;
  cursor: pointer;
}
.newPostBodyContainer .activityIconContainer .imgIconContainer p span,
.newPostBodyContainer .activityIconContainer .greetingsIconContainer p span,
.newPostBodyContainer .activityIconContainer .videoIconContainer p span,
.newPostBodyContainer .activityIconContainer .documentIconContainer p span,
.newPostBodyContainer .activityIconContainer .gifIconContainer p span,
.newPostBodyContainer .activityIconContainer .linkIconContainer p span {
  color: #f7941d;
  font-weight: bold;
  padding: 0 5px;
  border-radius: 100%;
  background-color: #f7941d30;
}
.newPostBodyContainer .activityIconContainer .imgIconContainer:hover,
.newPostBodyContainer .activityIconContainer .greetingsIconContainer:hover,
.newPostBodyContainer .activityIconContainer .videoIconContainer:hover,
.newPostBodyContainer .activityIconContainer .documentIconContainer:hover,
.newPostBodyContainer .activityIconContainer .gifIconContainer:hover,
.newPostBodyContainer .activityIconContainer .linkIconContainer:hover {
  background-color: var(--primary-hover);
  border-radius: 10px;
}
.newPostBodyContainer .activityIconContainer .imgIconContainer img {
  padding-left: 20%;
}

.newPostBodyContainer .activityIconContainer .videoIconContainer img {
  padding-left: 22%;
}
.newPostBodyContainer .activityIconContainer .documentIconContainer img {
  padding-left: 23%;
}
.newPostBodyContainer .activityIconContainer .gifIconContainer img {
  padding-left: 15%;
}

.newPostBodyContainer .activityIconContainer .greetingsIconContainer img {
  padding-left: 22%;
}
.newPostBodyContainer .msgSendIconContainer {
  padding-left: 5%;
  margin-right: 6%;
  cursor: pointer;
}
.newPostBodyContainer .msgSendIconContainer img {
  padding-left: 5px;
}
.newPostBodyContainer .msgSendGreyIconContainer {
  padding-left: 5%;
  margin-right: 6%;
}
.newPostBodyContainer .msgSendGreyIconContainer img {
  padding-left: 5px;
  cursor: unset !important;
}
.greetingPostPopupBox2 {
  min-height: 490px !important;
}
.greetingsOptionsContainer {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.greetingsOptionsContainer .createPostWelcomeContainer,
.greetingsOptionsContainer .createPostKudosContainer,
.greetingsOptionsContainer .createPostBirthdayContainer,
.greetingsOptionsContainer .createPostWorkAnniContainer {
  display: flex;
  margin: 0 10px 15px 10px;
  padding: 10px 20px;
  border-radius: 14px;
  cursor: pointer;
}
.greetingsOptionsContainer .createPostWelcomeContainer {
  background-color: #fff5f2;
}
.greetingsOptionsContainer .createPostKudosContainer {
  background-color: #f0f0ff;
}
.greetingsOptionsContainer .createPostBirthdayContainer {
  background-color: #fff4e5;
}
.greetingsOptionsContainer .createPostWorkAnniContainer {
  background-color: #eaf5ff;
}
.greetingsOptionsContainer .createPostWelcomeImgContainer,
.greetingsOptionsContainer .createPostKudosImgContainer,
.greetingsOptionsContainer .createPostBirthdayImgContainer,
.greetingsOptionsContainer .createPostWorkAnniImgContainer {
  width: 10%;
  margin: 10px 20px 0px 10px;
}
.greetingsOptionsContainer .createPostWelcomeTextContainer,
.greetingsOptionsContainer .createPostKudosTextContainer,
.greetingsOptionsContainer .createPostBirthdayTextContainer,
.greetingsOptionsContainer .createPostWorkAnniTextContainer {
  width: 90%;
}
.greetingsOptionsContainer .createPostWelcomeTextContainer span,
.greetingsOptionsContainer .createPostKudosTextContainer span,
.greetingsOptionsContainer .createPostBirthdayTextContainer span,
.greetingsOptionsContainer .createPostWorkAnniTextContainer span {
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins";
  margin-bottom: 5px;
}
.greetingsOptionsContainer .createPostWelcomeTextContainer p,
.greetingsOptionsContainer .createPostKudosTextContainer p,
.greetingsOptionsContainer .createPostBirthdayTextContainer p,
.greetingsOptionsContainer .createPostWorkAnniTextContainer p {
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins";
  color: #555;
}
.createPostWelcomeTextContainer span {
  color: #fb7158;
}
.createPostKudosTextContainer span {
  color: #8a88ed;
}
.createPostBirthdayTextContainer span {
  color: #ffb703;
}
.createPostWorkAnniTextContainer span {
  color: #77a2c7;
}

.celebratePostPublishBtn {
  margin-right: 30px;
  color: #1296b0;
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
}

.greetingsOptionsContainer div img {
  cursor: pointer;
}
.welcomeOptionGreetingsContainer {
  width: 100%;
}
.welcomeOptionGreetingsContainer .welcomeImgContainer .welcomeImg {
  display: flex;
  flex: 0 0 33.33%;
  padding: 0.5rem 1rem;
  flex-direction: column;
  position: relative;
}
.welcomeOptionGreetingsContainer .welcomeImgContainer .welcomeImg img {
  width: 100%;
  height: 160px;
  border-radius: 10px;
  cursor: pointer;
}
.notSelectedGreetings {
  position: relative;
  display: none;
}
.welcomeImg svg > g {
  mix-blend-mode: multiply;
}
.selectedGreetings {
  position: absolute;
  left: 5px;
  display: block;
  cursor: pointer;
  border-radius: 10px 10px 0px 0px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.735) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  mix-blend-mode: multiply;
  width: 86%;
  margin-left: 10px;
  top: 6px;
}
.newPostPopupContent {
  font-size: 14px;
  flex-direction: row;
  justify-content: start;
}
.welcomeOptionGreetingsContainer .welcomeImgContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 25px 15px;
  margin-bottom: 100px;
}
.welcomeOptionBottomContainer {
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #f1f2f2;
  z-index: 999;
  background-color: #fff;
}
.welcomeOptionBottomContainer .welcomeOptionBottomWrapper {
  padding: 15px;
  text-align: end;
}
.welcomeOptionBottomContainer .welcomeOptionBottomWrapper span {
  margin-right: 30px;
  color: var(--people-sol, #bfbfbf);
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
}
.welcomeOptionBottomContainer .welcomeOptionBottomWrapper .active {
  color: var(--primary);
  cursor: pointer;
}

.newPostForEmployeePopup1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.newPostForEmployeePopup2 {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  min-width: 600px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  max-height: 620px;
  border-radius: 6px;
  min-height: 620px;
  max-width: 600px;
  margin-top: 50px;
}
.newPostForEmployeePopupContent {
  padding: 0;
  font-size: 14px;
  flex-direction: row;
  justify-content: start;
}
.selectEmployeeSearchContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: fixed;
}
.commonSearchListContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: fixed;
  z-index: 99;
}
.commonSearchList {
  box-sizing: border-box;
  background: #eff9fa;
  border: 0.5px solid #d8f0f2;
  border-radius: 4.65352px;
  margin: 20px 0;
  width: 93%;
  padding: 6px 12px;
  outline: none;
}
.commonSearchListContainer img {
  position: absolute;
  right: 5%;
}
.myinput[type="checkbox"]:before {
  position: relative;
  display: block;
  content: "";
  background: #fff;
}
.myinput[type="checkbox"]:after {
  position: relative;
  display: block;
  left: 2px;
  top: -11px;
  width: 7px;
  height: 7px;
  content: "";
  border: 1px solid #bababa;
  border-radius: 3px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
}
.myinput[type="checkbox"]:checked:after {
  background-image: url("../../../../../Assets/RightCheck.svg"),
    linear-gradient(135deg, #1296b0 0%, #1296b0 100%);
  border: none;
}
.myinput[type="checkbox"]:not(:disabled):checked:hover:after {
  background-image: url("../../../../../Assets/RightCheck.svg")
    linear-gradient(135deg, #1296b0 0%, #1296b0 100%);
  border: none;
}
.myinput[type="checkbox"]:not(:disabled):hover:after {
  background-color: #c9c9c9;
  border: none;
}
.myinput.large[type="checkbox"]:before {
  width: 20px;
  height: 20px;
}
.myinput.large[type="checkbox"]:after {
  top: -20px;
  width: 16px;
  height: 16px;
}
.selectEmployeeContainer {
  width: 100%;
}
.selectEmployeeContainer .selectEmployeeWrapper {
  width: 100%;
}
.selectEmployeeContainer
  .selectEmployeeWrapper
  .totalEmployeeSelectedContainer {
  padding: 70px 25px 60px 20px;
  width: 100%;
}
.socialCommonChkbox {
  padding-right: 18px;
}
.selectEmployeeContainer
  .selectEmployeeWrapper
  .totalEmployeeSelectedContainer
  .employeeList {
  display: flex;
  align-items: center;
  padding: 5px;
}
.selectEmployeeContainer
  .selectEmployeeWrapper
  .totalEmployeeSelectedContainer
  .employeeList
  .totalLikePopupImg {
  width: 52px;
  height: 52px;
  margin-right: 15px;
  cursor: pointer;
}
.selectEmployeeContainer
  .selectEmployeeWrapper
  .totalEmployeeSelectedContainer
  .employeeList
  .totalLikePopupImg
  img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.selectEmployeeContainer
  .selectEmployeeWrapper
  .totalEmployeeSelectedContainer
  .employeeList
  .totalLikePopupText
  h5 {
  color: var(--primary);
  font-family: Poppins;
  font-size: 16.869px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.99px;
}
.selectEmployeeContainer
  .selectEmployeeWrapper
  .totalEmployeeSelectedContainer
  .employeeList
  span {
  color: #747474;
  font-family: Poppins;
  font-size: 12.869px;
  font-style: normal;
}
.selectEmployeeContainer
  .selectEmployeeWrapper
  .totalEmployeeSelectedContainer
  .employeeList
  p {
  color: #747474;
  font-family: Poppins;
  font-size: 12.869px;
  font-style: normal;
}
.selectedEmployeeList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.06);
  margin: 15px 0;
}
.hideScrollBarContainer::-webkit-scrollbar {
  display: none;
}
.socialTagAndHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.announcementTypeContainer,
.birthdayTypeContainer,
.weddingTypeContainer,
.workAnnyTypeContainer,
.newJoiningTypeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.announcementTypeContainer,
.newJoiningTypeContainer,
.newsTypeContainer,
.workAnnyTypeContainer,
.weddingTypeContainer,
.birthdayTypeContainer,
.eventTypeContainer,
.kudosTypeContainer {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 6px;
}
.newJoiningTypeContainer img {
  width: 30px;
}
.workAnnyTypeContainer img,
.birthdayTypeContainer img,
.weddingTypeContainer img,
.noticeBoardTypeContainer img,
.eventTypeContainer img,
.kudosTypeContainer img,
.newsTypeContainer img {
  width: 21px;
}
.announcementTypeContainer article,
.newJoiningTypeContainer article,
.workAnnyTypeContainer article,
.weddingTypeContainer article,
.birthdayTypeContainer article,
.noticeBoardTypeContainer article,
.kudosTypeContainer article,
.eventTypeContainer article,
.newsTypeContainer article {
  height: 20px;
  width: 1.5px;
  margin: 0 8px;
  border-radius: 10px;
}
.announcementTypeContainer span,
.newJoiningTypeContainer span,
.workAnnyTypeContainer span,
.weddingTypeContainer span,
.birthdayTypeContainer span,
.noticeBoardTypeContainer span,
.eventTypeContainer span,
.kudosTypeContainer span,
.newsTypeContainer span {
  text-align: end;
  font-size: 13px;
}
.announcementTypeContainer span {
  background-color: #fff1ef;
  color: #fb7158;
}
.birthdayTypeContainer span {
  background: #fcf5e4;
  color: #ffb703;
}
.weddingTypeContainer span {
  background: #ffecef;
  color: #f48498;
}
.workAnnyTypeContainer span {
  background: #e7f2ff;
  color: #71a5de;
}
.newJoiningTypeContainer span {
  background: #eafcf7;
  color: #84a59d;
}
.announcementTypeContainer {
  background-color: #fff1ef;
  color: #fb7158;
}
.newJoiningTypeContainer {
  background: #eafcf7;
  color: #84a59d;
}
.workAnnyTypeContainer {
  background: #e7f2ff;
  color: #71a5de;
}
.noticeBoardTypeContainer {
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  border-radius: 6px;
}
.weddingTypeContainer {
  background: #ffecef;
  color: #f48498;
}
.birthdayTypeContainer {
  background: #fcf5e4;
  color: #ffb703;
}
.newsTypeContainer {
  background: #ebebf5;
  color: #8a88ed;
}
.noticeBoardTypeContainer {
  background: #f4eaf3;
  color: #b69fb3;
}
.eventTypeContainer {
  background: #deefe3;
  color: #0db53c;
}
.kudosTypeContainer {
  background: #e4f8f2;
  color: #82c1ad;
}
.kudosTypeContainer article {
  background-color: #82c1ad;
}
.announcementTypeContainer article {
  background-color: #fb7158;
}
.newJoiningTypeContainer article {
  background-color: #84a59d;
}
.weddingTypeContainer article {
  background-color: #f48498;
}
.birthdayTypeContainer article {
  background-color: #ffb703;
}
.newsTypeContainer article {
  background-color: #8a88ed;
}
.noticeBoardTypeContainer article {
  background-color: #b69fb3;
}
.eventTypeContainer article {
  background-color: #0db53c;
}
.workAnnyTypeContainer article {
  background-color: #71a5de;
}

.liked {
  color: #1296b0;
  margin-left: 5px;
}
.disliked {
  margin-left: 5px;
  color: #f71d1d;
}
.unlike {
  margin-left: 5px;
  color: rgb(175, 175, 175);
}
.dislikeBtn,
.likeBtn {
  display: flex;
  align-items: center;
}

.clapLike {
  padding-left: 7px;
  color: #0db53c;
}
.kowledgeLike {
  padding-left: 7px;
  color: #f7941d;
}
.heartLike {
  padding-left: 7px;
  color: #fb7158;
}
.sadLike {
  padding-left: 7px;
  color: #f7941d;
}
.hateLike {
  padding-left: 7px;
  color: #6765cb;
}
.angryLike {
  padding-left: 7px;
  color: #e75034;
}

.likeGreyIconContainer,
.likBlueIconContainer,
.likeClapIconContainer,
.likeKnowledgeIconContainer,
.likeHeartIconContainer,
.dislikeGreyIconContainer,
.dislikeRedIconContainer,
.dislikesadIconContainer,
.dislikehateIconContainer,
.dislikeGreyIconContainer {
  display: flex;
  align-items: center;
}

.makeCommentBottomWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.makeCommentSendIcon img {
  margin-right: 10px;
}
.makeCommentBottomWrapper .makeCommentInput {
  width: 100%;
  margin: 0 10px;
}
.makeCommentBottomWrapper .inputCommentClass {
  width: 100%;
  outline: none;
  border: none;
  border: 1px solid #d8f0f2;
  border-radius: 30px;
  padding: 10px;
  position: relative;
}
.inputCommentClass__control textarea {
  outline: none;
  border: none;
  padding-top: 10px;
  padding-left: 15px;
}
.inputCommentClass__suggestions,
.inputReplyClass__suggestions {
  top: 15px !important;
  position: absolute;
  left: 51px;
  z-index: 1;
  margin-top: 14px;
  min-width: 100px;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
  padding: 20px;
  height: 300px;
  overflow: auto;
}
.commentTextContainer a {
  text-decoration: none;
}
.makeCommentProfilePic {
  height: 40px;
  width: 40px;
}
.makeCommentProfilePic img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  cursor: pointer;
}
.engagePostContentHolder {
  position: relative;
}
.doLikeList {
  position: absolute;
  left: 20px;
  bottom: 50px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.doLikeList .likeList {
  display: flex;
  align-items: baseline;
  padding: 3px 7px 0px 7px;
}
.doLikeList .likeList div {
  margin: 0 5px;
}
.doLikeList .likeList img {
  width: 30px;
  height: 30px;
}
.doLikeList .likeList .thumbLike:hover,
.doLikeList .likeList .clapLike:hover,
.doLikeList .likeList .knowledgeLike:hover,
.doLikeList .likeList .heartLike:hover {
  transition: transform 0.4s ease-in-out;
  position: relative;
  transform: scale(1.1) translateY(-10px);
}
.doLikeList .likeList .heartLike img {
  width: 28px;
  height: 28px;
}
.doLikeList .likeList .knowledgeLike img {
  padding-bottom: 2px;
}

.doCommentLikeList {
  position: absolute;
  left: 0px;
  bottom: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.doCommentLikeList .likeList {
  display: flex;
  align-items: baseline;
  padding: 3px 7px 0px 7px;
}
.doCommentLikeList .likeList div {
  margin: 0 5px;
}
.doCommentLikeList .likeList img {
  width: 30px;
  height: 30px;
}
.doCommentLikeList .likeList .thumbLike:hover,
.doCommentLikeList .likeList .clapLike:hover,
.doCommentLikeList .likeList .knowledgeLike:hover,
.doCommentLikeList .likeList .heartLike:hover {
  transition: transform 0.4s ease-in-out;
  position: relative;
  transform: scale(1.1) translateY(-10px);
}
.doCommentLikeList .likeList .heartLike img {
  width: 28px;
  height: 28px;
}
.doCommentLikeList .likeList .knowledgeLike img {
  padding-bottom: 2px;
}

.doCommentDislikeList {
  position: absolute;
  left: 80px;
  bottom: 50px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.doCommentDislikeList .likeList {
  display: flex;
  align-items: baseline;
  padding: 3px 7px 0px 7px;
}
.doCommentDislikeList .likeList div {
  margin: 0 5px;
}
.doCommentDislikeList .likeList img {
  width: 30px;
  height: 30px;
}
.doCommentDislikeList .likeList .thumbLike:hover,
.doCommentDislikeList .likeList .clapLike:hover,
.doCommentDislikeList .likeList .knowledgeLike:hover,
.doCommentDislikeList .likeList .heartLike:hover {
  transition: transform 0.4s ease-in-out;
  position: relative;
  transform: scale(1.1) translateY(-10px);
}
.doCommentDislikeList .likeList .heartLike img {
  width: 28px;
  height: 28px;
}
.doCommentDislikeList .likeList .knowledgeLike img {
  padding-bottom: 2px;
}

.likeGreyIconContainer span {
  padding-top: 3px;
}
.dislikeGreyIconContainer span {
  padding-top: 2px;
}
.dislikeGreyIconContainer img {
  padding-top: 4px;
}
.likBlueIconContainer span {
  padding-top: 3px;
}
.likeKnowledgeIconContainer span {
  padding-top: 2px;
}
.likeKnowledgeIconContainer img {
  padding-bottom: 2px;
}
.dislikeRedIconContainer img {
  height: 18px;
  width: 18px;
}
.dislikehateIconContainer img {
  padding-bottom: 6px;
}

.commentLikeList {
  position: absolute;
  left: 30px;
  bottom: 50px;
  background-position: top;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.dislike {
  left: 200px;
  bottom: 50px;
}
.commentLikeList .likeList {
  display: flex;
  align-items: baseline;
  padding: 3px 7px 0px 7px;
}
.commentLikeList .likeList div {
  margin: 0 5px;
}
.commentLikeList .likeList img {
  width: 30px;
  height: 30px;
}
.commentLikeList .likeList .thumbLike:hover,
.commentLikeList .likeList .clapLike:hover,
.commentLikeList .likeList .knowledgeLike:hover,
.commentLikeList .likeList .heartLike:hover {
  position: relative;
  bottom: 16px;
}
.commentLikeList .likeList .heartLike img {
  width: 28px;
  height: 28px;
}
.commentLikeList .likeList .knowledgeLike img {
  padding-bottom: 2px;
}
.parentCommentLike {
  padding: 15px 7px;
}
.parentCommentDisLike {
  padding: 15px 7px 15px 0px;
}
.reactionsContainer {
  padding: 0 40px;
}
.reactionWrapper h4 {
  font-size: 14px;
  color: #555;
  font-weight: 400;
  margin-top: 15px;
}
.reactionAllList {
  display: flex;
  align-items: center;
  width: 100%;
}
.reactionProfileImg {
  height: 40px;
  width: 40px;
  margin: 5px 0px;
  margin-right: 15px;
}
.reactionProfileImg img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}
.mainCommentContainer h5 {
  font-size: 14px;
  color: #555;
  font-weight: 400;
}
.reactionLikeImg {
  height: 20px;
  width: 20px;
  position: relative;
  top: -20px;
  left: 27px;
}
.reactionLikeImg img {
  height: 100%;
  width: 100%;
}
.likeHeaderContainer .likeHeaderWrapper h2 {
  font-size: 18px;
  margin-bottom: 20px;
}
.likeListWithImgContainer {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.likeListWithImgContainer div {
  margin-right: 30px;
  display: flex;
  align-items: center;
}
.likeListWithImgContainer img {
  margin-bottom: 2px;
}
.likeListWithImgContainer div span {
  margin-left: 5px;
}
.likeListWithImgContainer .likeList,
.likeListWithImgContainer .likeListWithThumb,
.likeListWithImgContainer .likeListWithClap,
.likeListWithImgContainer .likeListWithHeart,
.likeListWithImgContainer .likeListWithKnowledge,
.likeListWithImgContainer .likeListWithBad,
.likeListWithImgContainer .likeListWithAngry {
  padding: 3px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.likeListWithImgContainer .likeList.active,
.likeListWithImgContainer .likeListWithThumb.active,
.likeListWithImgContainer .likeListWithClap.active,
.likeListWithImgContainer .likeListWithHeart.active,
.likeListWithImgContainer .likeListWithKnowledge.active,
.likeListWithImgContainer .likeListWithBad.active,
.likeListWithImgContainer .likeListWithAngry.active {
  background-color: #d8f0f2;
}
.likeListWithKnowledge img {
  margin-bottom: 5px;
}
.likeListWithThumb span {
  color: #1296b0;
}
.likeListWithHeart span,
.likeListWithKnowledge span,
.likeListWithBad span,
.likeListWithAngry span {
  color: #bfbfbf;
}
.likeListWithClap span {
  color: #0db53c;
}
.socialCustomProfilePic {
  color: var(--primary);
  width: 50px;
  height: 50px;
  font-size: 30px;
  border-radius: 100%;
  font-weight: 500;
  background-color: #e9f3f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.socialHeaderCustomProfilePic {
  color: var(--primary);
  width: 42px;
  height: 42px;
  font-size: 25px;
  margin-right: 12px;
  border-radius: 100%;
  font-weight: 500;
  background-color: #e9f3f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.socialInputCustomProfilePic {
  color: var(--primary);
  margin: 12px;
  width: 50px;
  height: 50px;
  font-size: 30px;
  border-radius: 100%;
  font-weight: 500;
  background-color: #e9f3f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.commentCustomProfilePic {
  color: var(--primary);
  width: 41px;
  height: 41px;
  font-size: 21px;
  border-radius: 100%;
  font-weight: 500;
  background-color: #e9f3f4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.makeCommentCustomProfilePic {
  color: var(--primary);
  width: 44px;
  height: 44px;
  font-size: 22px;
  border-radius: 100%;
  font-weight: 500;
  background-color: #e9f3f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.eventVerticleLine {
  height: 35px;
  width: 1px;
  background-color: #bfbfbf;
  margin: 0 20px;
}
.eventAttendTextContainer {
  display: flex;
}
.socialVerticalLine {
  height: 100%;
  width: 1px;
  align-self: center;
  background-color: var(--grey);
  border-radius: 50px;
}
.replyOnParentCommentContainer {
  margin-bottom: 25px;
}
.replyOnParentCommentContainer .replyOnParentCommentWrapper {
  display: flex;
  align-items: center;
  margin-left: 55px;
}
.replyOnParentCommentImg {
  height: 40px;
  width: 40px;
}
.replyOnParentCommentImg img {
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 100%;
}
.replyOnParentCommentInputbox {
  width: 100%;
}
.replyOnParentCommentInputbox .inputReplyClass {
  outline: none;
  border: none;
  width: 100%;
  border: 1px solid #d8f0f2;
  border-radius: 30px;
  padding: 7px 10px;
  margin-left: 7px;
}
.inputReplyClass__control textarea {
  outline: none;
  border: none;
  padding-top: 7px;
  padding-left: 10px;
}
.replyBtn {
  display: flex;
  align-items: end;
  justify-content: end;
  margin-top: 12px;
  cursor: pointer;
}
.replyBtn span {
  background-color: #1296b0;
  color: #fff;
  border-radius: 15px;
  font-weight: 600;
  text-align: center;
  padding: 5px 15px;
}
.likeAndReplyList {
  display: flex;
  align-items: center;
}
.commentLikeIcons {
  display: flex;
  align-items: center;
}
.commentLikeIcons .thumbLikeRoundIcon,
.commentLikeIcons .clapLikeRoundIcon,
.commentLikeIcons .knowledgeLikeRoundIcon,
.commentLikeIcons .heartLikeRoundIcon {
  height: 30px;
  width: 30px;
}
.commentLikeIcons .thumbLikeRoundIcon img,
.commentLikeIcons .clapLikeRoundIcon img,
.commentLikeIcons .knowledgeLikeRoundIcon img,
.commentLikeIcons .heartLikeRoundIcon img {
  height: 100%;
  width: 100%;
}
.postLikeIconContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  left: -3px;
}
.postLikeIconContainer span {
  position: relative;
  font-size: 13px !important;
}
.likeFirstCls {
  margin-left: 10px;
  margin-right: 20px;
}
.likeSecondCls {
  margin-right: 20px;
}
.likeThirdCls {
  left: -8px;
  margin-right: 15px;
}
.likeFourthCls {
  left: -20px;
}
.commentLikeIcons .clapLikeRoundIcon {
  position: relative;
  left: -10px;
}
.commentLikeIcons .knowledgeLikeRoundIcon {
  position: relative;
  left: -32px;
}
.commentLikeIcons .heartLikeRoundIcon {
  position: relative;
  left: -20px;
}
.commentLikeIcons span {
  position: relative;
  left: -15px;
  font-size: 15px !important;
}
.likeAndReplyList .totalReplyCount {
  color: #919191;
  position: relative;
  left: -25px;
  font-size: 13px;
}
.likeAndReplyList h6 {
  position: relative;
  left: -10px;
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 15px;
}
.likeAndReplyList h5 {
  position: relative;
  left: -5px;
}
.likeAndReplyList .totalReplyCount {
  position: relative;
  left: 7px;
  padding-bottom: 5px;
}
.likeAndReplyList .totalReplyText {
  color: #919191;
  font-size: 13px;
}

.commentVerticalLine {
  margin-right: 7px;
  margin-left: 5px;
}
.msgActivityContainer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}
.socialPostActivityHolder {
  border: none;
  border-radius: 6px;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 999;
  top: 40px;
  right: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.socialPostActivityHolder .headingFilterHeader h5 {
  width: 100px;
  font-size: 12px;
  color: #555;
  text-wrap: nowrap;
}
.socialPostActivityHolder .headingFilterHeader {
  cursor: pointer;
  text-align: start;
}
.socialPostActivityHolder .headingFilterHeader:hover {
  background-color: rgba(216, 240, 242, 0.4);
}

.deleteMsgConfirmAlert .react-confirm-alert-button-group button:nth-child(1) {
  background: none;
  color: red;
  font-size: 1rem;
  font-weight: 600;
  margin-right: 10px;
  padding: 8px 35px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.deleteMsgConfirmAlert .react-confirm-alert-button-group button:nth-child(2) {
  font-size: 16px;
  font-weight: 400;
  padding: 8px 10px;
  color: #fff;
  background-color: #1296b0;
}
.deleteMsgConfirmAlert .react-confirm-alert-button-group button:nth-child(3) {
  font-size: 16px;
  font-weight: 400;
  padding: 5px 5px;
  color: #fff;
  background-color: #1296b0;
}
.socialPostDeleteContainer {
  background-color: #fff;
  color: #555;
  border-radius: 8px;
  font-family: Poppins;
}
.socialPostDeleteHaeder {
  color: #1296b0;
  padding: 15px 0px;
  border-radius: 8px 8px 0px 0px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06);
}
.socialPostDeleteHaeder h4 {
  color: #1296b0;
  padding: 0px 20px;
  font-weight: 500;
}
.socialPostDeleteHaeder span {
  margin: 0px 20px;
}
.socialPostDeleteBody {
  margin: 20px;
}
.socialPostDeleteBody p {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 40px;
}
.socialPostDeleteBtn {
  border-top: 1px solid #eee;
  padding: 20px;
}
.socialPostDeleteBtn button {
  border: none;
  font-size: 15px;
  font-family: Poppins;
  font-weight: 500;
  float: right;
  padding: 8px 30px;
  border-radius: 8px;
}
.socialPostDeleteBtn button:nth-child(2) {
  color: #f71d1d;
  border-radius: 4px;
  background: var(--White, #fff);
  box-shadow: 0px 4px 8px 0px rgba(207, 207, 207, 0.4),
    0px 1px 10px 0px rgba(0, 0, 0, 0.05) inset;
  margin-right: 20px;
}
.socialPostDeleteBtn button:nth-child(1) {
  color: #fff;
  border-radius: 4px;
  background: var(--PeopleSOl-Primery, #1296b0);
  box-shadow: 0px 4px 8px 0px rgba(18, 150, 176, 0.2),
    0px 4px 10px 0px rgba(0, 0, 0, 0.15) inset;
}
.inputLinkContainer {
  width: 100%;
}
.inputLinkWrapper {
  position: relative;
  display: flex;
  background-color: #eff9fa;
  padding: 15px;
  border-radius: 8px;
  border-bottom: 25px solid #d9eff3;
  margin: 0 20px;
}
.inputLinkText {
  padding: 0 15px;
  word-break: break-all;
}
.inputLinkText a {
  color: #1296b0;
}
.inputLinkCloseIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.sharePostPopup1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.sharePostPopup2 {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  max-height: 70%;
  border-radius: 6px;
  min-height: 60%;
  max-width: 52%;
  min-width: 52%;
}
.sharePostContentHolder {
  padding: 0px;
}
.sharePostContainer {
  margin: 0 20px;
}
.shareBottomContainer {
  position: fixed;
  display: flex;
  justify-content: end;
  width: 100%;
  bottom: 0px;
  background-color: white;
  z-index: 2;
}
.shareBottomContainer button {
  margin: 20px 30px 20px 0px;
}
.saveBottomContainer {
  position: fixed;
  display: flex;
  width: 100%;
  bottom: 0px;
  left: 555px;
  background-color: white;
}
.sharedSocialPostDataListing {
  border: 1px solid #f1f2f2;
  margin: 10px 20px;
  border-radius: 10px;
}
.shareContentListing {
  padding: 5px 20px 0px 20px;
  word-break: break-all;
}

.makeReply {
  margin-left: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.viewReply {
  display: flex;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
}
.makeReply p {
  font-size: 15px;
  font-weight: 500;
  padding: 0 5px 10px 0;
}

.shareNewPostBodyImgContainer {
  width: 609px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
}
.celebrationImg {
  width: 100%;
}
.celebrationSharedImg {
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
}
.eventBodyTextContainer {
  font-family: Poppins;
  margin: 0 20px;
  padding: 7px 0;
}
.eventBodyTextWrapper h6 {
  color: #555;
}
.eventBodyTextWrapper p {
  font-size: 12px;
}
.eventBodyTextWrapper p span {
  color: #1296b0;
  padding-left: 4px;
}
.eventDataWrapper {
  margin-top: 5px;
  padding: 15px;
  background-color: #ebfbfc;
  border-radius: 8px;
  font-size: 13px;
}
.eventDateWapper,
.eventModeWapper,
.eventVanueWapper {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.eventLinkWapper {
  display: flex;
  align-items: flex-start;
  margin: 6px 0;
}
.eventLinkWapper span {
  color: #1296b0;
}
.eventDateWapper span,
.eventModeWapper span,
.eventLinkWapper span,
.eventVanueWapper span {
  padding-left: 10px;
  word-break: break-all;
}
.eventStatusWrapper {
  padding: 3%;
  border-top: 1px solid #f1f2f2;
}
.eventStatusWrapper .eventButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.eventStatusWrapper .eventButtons .attendBtn,
.eventStatusWrapper .eventButtons .notAttendBtn,
.eventStatusWrapper .eventButtons .mayAttentBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  width: 30%;
  padding: 1.5%;
}
.eventStatusWrapper .eventButtons .notAttendBtn {
  margin-left: 25px;
  margin-right: 25px;
}
.eventStatusWrapper .eventButtons .attendBtn {
  color: #1296b0;
  border: 1px solid #1296b0;
}
.eventStatusWrapper .eventButtons .notAttendBtn {
  color: #f71d1d;
  border: 1px solid #f71d1d;
}
.eventStatusWrapper .eventButtons .mayAttentBtn {
  color: #f7941d;
  border: 1px solid #f7941d;
}

.eventStatusWrapper .eventButtons .attendBtn:hover {
  background-color: #1296b0;
  color: white;
}
.eventStatusWrapper .eventButtons .notAttendBtn:hover {
  background-color: #f71d1d;
  color: white;
}
.eventStatusWrapper .eventButtons .mayAttentBtn:hover {
  background-color: #f7941d;
  color: white;
}
.eventStatusWrapper
  .eventButtons
  .attendBtn:hover
  img
  .eventStatusWrapper
  .eventButtons
  .notAttendBtn:hover
  img
  .eventStatusWrapper
  .eventButtons
  .mayAttentBtn:hover
  img {
  background: #fff;
  color: white;
}
.eventDisabledBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  width: 30%;
  padding: 1.5%;
  background-color: #e6e6e6;
  color: #b8b8b8;
}
.eventDisabledBtn {
  cursor: unset !important;
}
.eventDisabledBtn img {
  width: 20px;
  height: 20px;
}

.normalImage {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease-in-out;
}

.normalImage:hover {
  transform: scale(1.2);
}

.eventStatusWrapper .eventButtons img {
  margin-right: 7px;
}
.postUpdateContainer div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.socialProfilePic {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 6px;
}
.communityViewPageProfilePic {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.socialInputProfilePic {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin: 12px !important;
}
.commentProfilePic {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.postUpdateContainer div span {
  height: 40px;
  width: 0.5px;
  background-color: #bfbfbf;
  display: inline-block;
}
.postUpdateContainer div img {
  margin: 0 20px;
  cursor: pointer;
}
.connectSearchPostContainer {
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
  background-color: #eff9fa;
  position: absolute;
  top: 0;
  right: 0;
  width: 0%;
  transition: 1s all;
}
.connectSearchPostWrapper {
  display: flex;
  align-items: center;
}
.connectSearchPostWrapper input {
  width: 95%;
  outline: none;
  border: none;
  padding: 7px 15px;
  margin-top: 20px;
  background-color: #eff9fa;
}
.connectSearchPostWrapper img {
  margin-top: 18px;
  cursor: pointer;
  width: 20px;
}
.connectSearchPostContainer.active {
  width: 100%;
}
.searchResultNotFoundContainer {
  width: 100%;
  position: absolute;
  border-radius: 8px;
  z-index: 999;
  margin-top: 5px;
  position: sticky;
}
.searchResultNotFoundWrapper {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #eff9fa;
  background: #fff;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
}
.searchResultContainer {
  width: 100%;

  border-radius: 8px;
  margin-top: 5px;
  z-index: 999;
  position: sticky;
}
.searchResultWrapper {
  max-height: 400px;
  overflow: auto;
  border-radius: 8px;
  border: 1px solid #eff9fa;
  background: #fff;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
}
.searchResultListContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.searchResultRight {
  display: flex;
  align-items: center;
}
.searchResultRight img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 15px;
}
.searchResultRight h6 {
  color: #1296b0;
  font-weight: 500;
  margin-right: 15px;
}
.searchResultRight p {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.searchResultRight span {
  font-size: 13px;
}
.searchResultRight p article {
  font-size: 30px;
  font-weight: bold;
  margin-right: 5px;
  color: #bfbfbf;
  padding-bottom: 15px;
}
.searchResultLeft {
  cursor: pointer;
}

.selectPostTypePopupBox1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.selectPostTypePopupBox2 {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  max-height: 350px;
  border-radius: 6px;
  min-height: 350px;
  min-width: 500px;
  max-width: 500px;
  left: -60px;
  margin-top: 50px;
}
.selectPostTypePopupBox2 .popupContentHolder {
  flex-grow: 0;
}
.selectPostTeamContainer,
.selectPostCommunityContainer,
.selectPostOrganizationContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px;
}

.selectPostTeamContainer div,
.selectPostCommunityContainer div,
.selectPostOrganizationContainer div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selectPostTypeContainer .selectPostTypeWrapper {
  padding: 0 40px 0px 10px;
}
.selectPostTypeContainer .commClass {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0px;
  background: white;
}

.noticeBoardHeaderContainer {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  padding: 0 25px;
}
.noticeBoardHeaderButton {
  border-radius: 7px;
  padding: 5px 17px;
  font-size: 13px;
  color: rgb(182, 159, 179);
  background-color: rgb(244, 234, 243);
}

.NoticeBoardTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
  padding: 12px 20px;
}
.NoticeBoardTitleContainer label {
  font-size: 13px;
  color: #bfbfbf;
}
.NoticeBoardFooterContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid #eee;
  padding: 20px;
}
.NoticeBoardFooterContainer .showCommunityRightHeaderContainer {
  background-color: #1296b0;
  border-radius: 8px;
}
.NoticeBoardFooterContainer .showCommunityRightHeaderContainer span {
  width: 100px;
}
.NoticeBoardFooterContainer .showCommunityRightHeaderContainer span a {
  font-weight: 500;
  font-size: 16px;
  color: white;
  text-decoration: none;
  word-break: break-all;
}
.NoticeBoardFooterContainer p {
  font-size: 14px;
  padding-right: 30px;
  word-break: break-all;
}
.NoticeBoardTitleContainer h5 {
  word-break: break-all;
  margin-right: 30px;
  color: #555;
  font-size: 16px;
}
.NoticeBoardTitleContainer span {
  white-space: nowrap;
}
.NoticeBoardTextContainer p {
  word-break: break-all;
  margin-bottom: 15px;
  font-size: 14px;
}
.NoticeBoardImageContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 350px;
}
.NoticeBoardImageContainer img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.announcementHeaderContainer {
  display: flex;
  flex-direction: column;
}
.announcementHeaderContainer label {
  color: #1296b0;
  font-size: 17px;
  font-family: Poppins;
  font-weight: 500;
  margin-right: 10px;
}
.posting-socialpost {
  padding-right: 30px;
  font-size: 15px;
  color: #c8c8c8;
  font-weight: 500;
  font-family: Poppins;
  position: absolute;
  right: -6px;
  bottom: -50px;
}
.selectCommunityTypePopupBox2 {
  min-height: 300px !important;
}
.socialPostTitleAndTimeContainer {
  display: flex;
  flex-direction: column;
}
.socialPostTitleAndTimeContainer label {
  text-transform: capitalize;
  color: #555;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
}
.eventLinContainer {
  margin: 10px 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: #ebfbfc;
  border-radius: 10px;
}
.eventLinkWrapper .newsLink {
  display: flex;
  align-items: center;
}
.eventLinkWrapper .newsLink a {
  color: #1296b0;
}
.eventLinkWrapper .newsLink span {
  margin-left: 10px;
}
.eventLinkWrapper {
  width: 60%;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.eventDateContainer {
  width: 40%;
  margin-right: 20px;
}
.eventDateWrapper {
  background-color: #fff;
  padding: 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #555;
}
.eventDateWrapper img {
  height: 22px;
  margin-right: 10px;
}
.eventLinkWrapper .newsLink img {
  height: 24px;
}
.eventAttendImgContainer .eventAttendImg img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  border: 1px solid white;
}
.eventAttendContainer {
  margin: 20px 0 10px 15px;
  cursor: pointer;
}
.eventAttendContainer,
.eventAttendImgContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.eventAttenders,
.eventNotAttenders,
.eventMayAttenders {
  font-size: 13px;
  color: #555;
}
.eventAttenders span,
.eventNotAttenders span,
.eventMayAttenders span {
  font-size: 13px;
  font-weight: 500;
}
.eventAttenders span {
  color: #1296b0;
}
.eventNotAttenders span {
  color: #f71d1d;
}
.eventMayAttenders span {
  color: #f7941d;
}
.eventAttendImg .eventAttendImgList img:nth-child(1) {
  position: relative;
}
.eventAttendImg .eventAttendImgList img:nth-child(2) {
  position: relative;
  left: -14px;
}
.eventAttendImg .eventAttendImgList img:nth-child(3) {
  position: relative;
  left: -24px;
}
.eventAttendCountContainer span {
  position: relative;
  left: -15px;
  color: #555;
}
.newsLinkContainer {
  width: 95%;
  padding: 20px;
  background-color: #ebfbfc;
  border-radius: 10px;
  margin: 10px 15px;
}
.newsLinkWrapper {
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newsLinkWrapper img:nth-child(1) {
  width: 25px;
  margin-right: 10px;
}
.newsLinkWrapper .newsLink {
  display: flex;
  align-items: center;
}
.newsLinkWrapper a {
  color: #1296b0;
}
.CreatePostSelectCommunity .popupContentHolder {
  display: block;
}
.workCommunityTeamHeaderContainer {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
  padding: 4px 23px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}
.workCommunityTeamHeaderShareContainer {
  padding: 5px 5px 0 0px;
  border-top: 1px solid #e6e6e6;
}
.workCommunityTeamHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.workCommunityTeamLeftContainer {
  display: flex;
  align-items: center;
}
.workCommunityTeamLeftContainer img {
  height: 42px;
}
.workCommunityTeamLeftContainer p {
  margin-left: 15px;
  font-weight: 500;
  color: #555;
}
.workCommunityTeamRightContainer span {
  font-weight: 500;
  padding: 5px 12px;
  background-color: #d8f0f2;
  border-radius: 8px;
  color: #1296b0;
  text-transform: capitalize;
}
.sharedAndPostTypeContainer {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
  padding: 7px 17px;
}
.sharedHeader {
  padding: 5px 10px 12px 10px !important;
}
.team-community-Header {
  padding: 10px 10px 15px 10px !important;
}
.team-community-details-Header {
  padding: 10px 10px 15px 10px !important;
}

.nestedSharedPostListContainer {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  height: auto;
}
.nestedSharedPostListWrapper {
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  margin-left: -10px;
  margin-right: 10px;
  margin-top: -10px;
  margin-bottom: 10px;
}
.commentLoadMoreBtn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.commentLoadMoreBtn h5 {
  color: #1296b0;
  background-color: #d8f0f2;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 5px;
}
.commentLoadMoreBtn .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  border-radius: 5px;
  padding: 0;
}
.EditorToolbar__root___3_Aqz {
  display: none;
}
.saving-post {
  padding: 20px;
  color: #bfbfbf;
  font-weight: 500;
}
.socialSharedPostProfileImg {
  width: 35px !important;
  height: 35px !important;
  position: absolute;
  left: 25px;
  top: 25px;
}
.announcementImg {
  height: 300px;
  width: 640px;
  object-fit: cover;
}
.createPostTypeImgContainer {
  background-color: #d8f0f2;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 100%;
  margin-right: 20px;
}
.emplistWithSymbolContainer {
  position: absolute;
  z-index: 999;
  height: 300px;
  min-width: 90%;
  max-width: 100%;
  overflow: auto;
  padding: 10px 20px;
  margin: 0 20px;
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.06);
}
.emplistWithSymbolContainer .emplistWithSymbolWrapper {
  padding: 2px 10px;
}
.emplistWithSymbolContainer .emplistWithSymbolWrapper:hover {
  background-color: #1296b023;
}
.emplistWithSymbolContainer .emplistWithSymbolWrapper p {
  color: #1296b0;
  margin: 10px 0;
  cursor: pointer;
}
.nestedSharedPostAttachmentShower {
  width: "100%" !important;
  border: "none";
  border-radius: "10px";
  height: "528px";
  overflow: "auto";
}
.nestedSharedPostAttachmentShower::-webkit-scrollbar {
  display: none;
}
.socialsearch-holdercontainer {
  position: fixed;

  width: 46%;
  min-height: 75px;
  z-index: 2;
  background-color: #fff;
}
.nameofposter {
  font-weight: 500;
}
.socialCustomProfilePicShareCommunity {
  color: var(--primary);
  margin: 12px;
  width: 35px;
  height: 35px;
  font-size: 22px;
  border-radius: 100%;
  font-weight: 500;
  background-color: #e9f3f4;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 25px;
  top: 27px;
}
.socialCustomProfilePicCommunity {
  color: var(--primary);
  width: 37px;
  height: 37px;
  font-size: 22px;
  border-radius: 100%;
  font-weight: 500;
  background-color: #e9f3f4;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 25px;
  top: 28px;
}
.prTeamCommunity {
  position: absolute;
  left: 25px;
  top: 28px;
  height: 37px;
  width: 37px;
  margin: 0 !important;
}
.prTeamCommunityShared {
  position: absolute;
  left: 23px;
  top: 24px;
  height: 37px;
  width: 37px;
  margin: 8px;
}
.postBodySocialspans span {
  font-weight: 500;
  color: #555555 !important;
  margin-right: 1px !important;
  cursor: default !important;
}

.usertag {
  cursor: pointer !important;
  color: #1296b0 !important;
  font-weight: 500 !important;
  margin-right: 4px;
}
.usertag:hover {
  text-decoration: underline;
}

.empty-line {
  height: 20px;
}

.postBodySocialspans span:hover {
  font-weight: 500 !important;
}

.postBodySocialspans a {
  font-weight: 500;
  margin-right: 2px !important;
}

.socialViewsSpanContainer {
  color: var(--secondary-text);
  margin-left: 3px;
  font-size: 12px;
}
.socialViewsImgContainer {
  height: 13px;
  position: relative;
  bottom: -2px;
}
.announcementViewsContainer {
  position: relative;
  bottom: -5px;
  display: flex;
  align-items: baseline;
  cursor: pointer;
}
.announcementViewsContainer span {
  color: var(--secondary-text);
  font-size: 12px;
  position: relative;
  bottom: 1px;
}
.announcementViewsContainer img {
  height: 13px;
}
.dateAndViewsContainer {
  display: flex;
  align-items: center;
}
.dateAndViewsContainer article {
  margin: 0 5px;
  padding: 5px;
  border-radius: 5px;
}
.dateAndViewsContainer article:hover {
  background-color: #80808020;
}
.announcementViewsContainer article {
  margin: 0 5px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: baseline;
}
.announcementViewsContainer article:hover {
  background-color: #80808020;
}
.announcementViewsContainer article span {
  margin-left: 3px !important;
}
.noticeViewsContainer {
  position: relative;
  bottom: -5px;
  display: flex;
  align-items: baseline;
  cursor: pointer;
}
.noticeViewsContainer span {
  color: var(--secondary-text);
  font-size: 12px;
}
.noticeViewsContainer article {
  margin: 0 5px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: baseline;
}
.noticeViewsContainer article:hover {
  background-color: #80808020;
}
.noticeViewsContainer article span {
  margin-left: 3px !important;
}

.gifComponentContainer {
  margin-top: 10px;
  padding: 0 10px;
  width: 33.33%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.gifComponentContainer .gifComponentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 12px;
  background-color: #0000000f;
}
.gifComponentContainer .gifComponentWrapper p {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(103, 103, 103, 0.44);
  padding: 25px;
  border-radius: 100%;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}
.gifComponentWrapper:hover {
  border-color: #1296b0;
  background-color: var(--primary-hover);
}
.gifComponentWrapper:hover p {
  color: #1296b0;
  background-color: #1296b03e;
}
.gridImg {
  position: relative;
}

.customCommunityProfilePicInSocial {
  color: var(--primary);
  margin-right: 10px;
  width: 48px;
  height: 48px;
  font-size: 20px;
  border-radius: 100%;
  font-weight: 500;
  background-color: #e9f3f4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
}
.nestedCommunityCustomProfilePic {
  color: var(--primary);
  margin-right: 10px;
  width: 48px;
  height: 48px;
  font-size: 20px;
  border-radius: 100%;
  font-weight: 500;
  background-color: #d8f0f2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  margin-bottom: 8px;
}

.sharePostGreyBtn {
  background-color: #74747479;
  color: #747474;
  cursor: unset !important;
}
.socialhoverComp:hover {
  color: #1296b0 !important;
}
.homeEngageMainCenterContainer .carousel .slide img {
  padding-bottom: 30px;
}
.socialPostDetailsHeader {
  line-height: 16px;
}
.socialPostDetailsHeader p {
  font-size: 13px;
}
.socialPostDetailsHeader span {
  font-size: 12px;
}
.dateAndViewsHeaderContainer {
  display: flex;
  align-items: center;
}
.dateAndViewsHeaderContainer article {
  margin: 0 5px;
  padding: 5px;
  border-radius: 5px;
}
.dateAndViewsHeaderContainer article:hover {
  background-color: #80808020;
}
@media screen and (max-width: 1199px) {
  .dateAndViewsHeaderContainer {
    display: block;
  }
}
.noPostfoundContainer {
  width: 100%;
  margin-top: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noPostfoundWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #a1a1a1;
}
.noPostfoundWrapper img {
  margin-bottom: 20px;
}

.showAttachmentSigngleSharedImages {
  margin: 12px;
  background: black;
}
